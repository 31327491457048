import { ValueHelper } from "@utils/ValueHelper";

export const getRedirUrlParts = (redirUrl: string): string[] => {
    const parts = redirUrl.split("/");
    parts.shift();

    return parts;
};

export const getClubId = (redirUrl: string): number | null => {
    const parts = getRedirUrlParts(redirUrl);

    // get clubId
    if ((parts[0] === "komunita" || parts[0] === "moje-domy") && ValueHelper.num(parseInt(parts[1], 10))) {
        return parseInt(parts[1], 10);
    }

    return null;
};

export const getInvitationToken = (redirUrl: string): string | null => {
    const parts = getRedirUrlParts(redirUrl);

    // get invitation token
    if (
        (parts[0] === "komunita" || parts[0] === "moje-domy") &&
        ValueHelper.num(parseInt(parts[1], 10)) &&
        parts[2] === "pozvanka" &&
        parts[3]
    ) {
        return parts[3];
    }

    return null;
};
